<template>
  <div class="w-full">
    <form
      @submit.prevent="submitBusinessBank"
      class="space-y-5"
    >
      <app-form-bank
        class="w-full mb-5"
        :labelText="$t('general.bank')"
        :placeholder="$t('general.placeholder_bank_search')"
        v-model="business.bank.bank"
        :country_id="business.country?.id ?? 0"
        :errorMessage="errors.bank_id"
        :disabled="editMode"
      />

      <app-form-input
        id="business.bank.account_number"
        name="business.bank.account_number"
        type="number"
        :labelText="$t('business.bank_details.account_number')"
        v-model="business.bank.account_no"
        :errorMessage="errors.account_no"
        :disabled="editMode"
        :numberOnly="true"
      />

      <app-form-input
        id="business.bank.confirm_account_number"
        name="business.bank.confirm_account_number"
        type="text"
        :labelText="$t('business.bank_details.account_holder')"
        v-model="business.bank.account_holder"
        :errorMessage="errors.account_holder"
        :disabled="editMode"
      />

      <app-button
        type="submit"
        v-if="!editMode"
        class="mt-3 w-full"
        :showf70Icon="false"
        :disabled="!hasChanged"
        :loading="loading"
      >
        {{ $t("general.save") }}
      </app-button>

      <app-button-outline class="mt-3 w-full" @onClick="editAction">
        {{ editMode ? $t("general.edit_information") : $t("general.cancel") }}
      </app-button-outline>
    </form>
  </div>
</template>

<script>
export default {
  props: ["menu_action", "edit_mode"],

  data() {
    return {
      hasChanged: false,
      emptyErrors: {
        bank_id: [],
        account_no: [],
        account_holder: [],
      },
    };
  },

  watch: {
    business: {
      handler: function () {
        this.hasChanged = true;
      },
      deep: true,
    },
  },

  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    editMode: {
      get() {
        return this.edit_mode;
      },
      set(v) {
        this.$emit("update:edit_mode", v);
      },
    },
  },

  methods: {
    async submitBusinessBank() {
      this.resetState();

      const businessBanks = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessBank",
        this.business.bank
      );

      if (businessBanks != null) {
        this.hasChanged = false;
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          5000
        );
      }
    },
    editAction() {
      this.editMode = !this.editMode;
      
      if (this.editMode == false) {
        this.$store.dispatch(
          "businessStore/getBusinessById",
          this.$route.params.business_id
        );
      }
    },
    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>